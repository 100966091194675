.heading{
    padding-top: 60px;
    text-align: center;
    margin-top: 40px;
}
.bottomHeading{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
}
.glassEffect {
  position: relative;
  width: 80%;
  height: 100%;
  background-color: lightgray;
  background-image: linear-gradient(to bottom left, lightgray, white);
  opacity: 0.9;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 -10px;    
  padding: 10px;
}  
.row {
  width: 100%;
}  
.column {
  width: 32%;
  margin: 3% 0;
  
}  
img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}
.butterfly{
  margin-bottom: 30px;
  font-size: 20px;
  background-image: url('./assets/Backgrounds/bg-p1.webp');
  background-repeat: no-repeat;
  min-width: 100%;
  padding: 25% 0px;
  background-size: contain;
  background-position: center;
}
@media (max-width: 600px) {
    .image-gallery {
      flex-direction: column;
    }  
    .row {
      width: 100%;
      justify-items: center;
      margin-left: 5%;
    }  
    .column {
      width: 90%;
      justify-items: center;
      margin: 5%;
      margin-top: 20px;
    }
    .butterfly{
      background-image: url('./assets/Backgrounds/bg-p1.webp');
      background-repeat: round;
      padding: 21% 0px;
      font-size: 16px;
    }
}

  