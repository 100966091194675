.App {
  text-align: center;
}

.secondCover {
  margin-top: -120px;
  width: 55%;
}

nav {
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.3);

}

nav img {
  padding: 0% 12px;
}

nav input[type="checkbox"] {
  display: none;
}

nav label.menu-icon {
  display: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 12px;
}

nav .menu-items {
  display: flex;
  justify-content:center;
  width: 100%;
  max-width: 800px;
  font-size: 14px;
}

nav .menu-items a {
  color: white;
  text-decoration: none;
  padding: 20px;
}

@media (max-width: 920px) {
  .box-container > a {
      flex-basis: 30%;
      padding: 5px;
  }
  nav img {
    padding: 0 10px;
  }
  nav label.menu-icon {
    display: block;
    padding-left: 65%;
  }

  nav .menu-items {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    max-width: 300px;
    background-color: rgba(0, 0, 0, 0.7);
    flex-direction: column;
    align-items: center;
    transition: right 0.3s ease-in-out;
  }

  

  nav input[type="checkbox"]:checked ~ .menu-items {
    right: 0;
  }
}

#gamessection {  
  background-size: cover;
  cursor: pointer;
  padding: 30px 0px;
}
#gamessection:hover{
  background-color: #333;
  z-index: 2;  
}

@media (max-width: 768px) {
  #gamessection {
    background-size: contain;
  }
  .flex-div{
    margin-left: 0% !important;
    justify-content: flex-start;
  }
}

.image-container {
  position: relative;
  text-align: center;
}
.shadow-left-text{
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 1);  
  font-weight: 500;
  text-align: left !important;
  width: auto !important;
  z-index: 100;
}
.shadow-left-text p{
  font-weight: 500;
  text-align: left !important;
  left: 60% !important;
  max-width:100% !important;
}
.shadow-left-text h4{
  -webkit-text-stroke: 2px rgba(139, 69, 19, 0.3);
  font-size: 3.5rem !important;
  line-height: 1.2em;
}
.image-txt-home {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 80%;
  text-align: center;
}
.affHead{
  padding-left: 20%;
  text-align: left !important;
  width: auto !important;
  max-width: 100% !important;
}

.image-txt-home h3,
h4 {
  margin: 1px;
}

.image-txt-home h3 {
  font-size: 3.5em;
  width: 100%;
}
.image-txt-home h4 {
  font-size: 2.5em;
  width: 100%;
}
.dotsstyle{
  position: absolute;
  top: -28%;
  left: -7%;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: 110%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

#banner{
  width: 100%;
  height: 90vh;
}
#banner2 {
  margin: 10% 0;
}

.overlay-banner2 {
  position: absolute;
  top: -25%;
  left: 0;
  margin: 0 7%;
  width: 86%;
  height: 150%;
  z-index: 1;
}


@media (max-width: 768px) {
  .image-txt-home {
    width: 100%;
  }
  
  .image-txt-home h3 {
    font-size: 2em; 
  }
  .image-txt-home h4 {
    font-size: 1.25em;
    width: 100%;
  }
}


@media (max-width: 480px) {
  #CustomModel > p{
    padding: 0% 10%;
  }
  .box-container img{
    height: 30px !important;
  }
  .box{
    padding: 25px !important;
  }
  .image-txt-home {
    width: 100%;
    text-align: left;
  }  
  .image-txt-home h3 {
    font-size: 2.5em;
  }
  .image-txt-home h4 {
    font-size: 1em;
    width: 100%;
  }
  #banner2 .image-txt-home h4 ,#banner2 .image-txt-home h3 ,#banner2 .image-txt-home p{
    font-size: 15px !important;    
  }
  #banner2 .image-txt-home p{
    margin: 0px;
    font-size: 8px !important;    
  }
  .image-txt-home p {
    margin: 0 auto; 
  }
  .overlay-banner2{
    top: 0%;
  }
  h2{
    font-size: 1.3em !important;
  }
  #aboutus {
    background-size: 40vw !important;
    margin-bottom: 10vh;
  }
  #aboutus h1{
    margin: 0px;
  }
  .halfbutterfly{
    width: 42vw !important;
  }
}





#aboutus {
  display: flex;
  align-items: center !important;
  flex-direction: column;
  padding: 10% 0;
}

#aboutus div {
  width: 65%;
  text-align: center;
}

h2 {
  font-size: 2rem;
  margin-top: 0;
}

#aboutus h2 {
  display: inline;
}

#aboutus h2 span {
  color: purple;
}

.image-container2 {
  width: 100%;
  max-width: 550px;
}
.image-container2 img{
  box-shadow: 3px 7px 10px 5px rgba(0, 0, 0, 0.3);
}
#aboutus a {
  color: red;
  font-weight: 500;
  font-size: small;
}

#aboutus p {
  margin: 10px 0;
}
.packagesbtncontainer{
  display: flex;
  justify-content: space-evenly;
  padding: 10px 25%;
}
.ourpackagesheaderbtn{
  padding: 10px 32px;
  border: white 1px solid;
  box-shadow: 0px 0px 10px 5px rgba(255, 0, 0, 0.5);
  color: white;
  background: rgb(80,28,115);
  background: linear-gradient(90deg, rgba(80,28,115,1) 0%, rgba(153,6,6,1) 35%, rgba(153,6,6,1) 59%, rgba(221,132,8,1) 100%);
  border-radius: 5px;
  z-index: 30;
  position: relative;
  cursor: pointer;
}
.ourpackagesheaderbtn:hover{
  background: black;
  transform: scale(1.1);
}
#categories {
  margin: 1% 0% 5%;
}
.categoryimgshadow{
  background-color: rgb(244, 244, 244);
  padding: 15px;
  clip-path: circle(50% at center);
}
.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: 5% 5%;
}
.box-container .box {
  box-shadow: 0px 0px 10px 5px rgba(255, 64, 64, 0.3), 
              0px 0px 15px 5px rgba(255, 64, 64, 0.3);
  padding: 40px;
  color: black;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-container img{
  width: auto;
  height: 50px;
}
.box {
  
  border-radius: 10px;
  border-top: 1px rgba(33, 0, 0, 0.2) solid;
  border-bottom: 1px rgba(33, 0, 0, 0.5) solid;
  border-left: 1px rgba(33, 0, 0, 0.2) solid;
  border-right: 1px rgba(33, 33, 33, 0.1) solid;
}

.image-text-home {
  z-index: 3;
  text-align: left;
  left: 60%;
  width: 65%;
}

.image-text-home h3 {
  font-weight: 400;
  font-size: large;
}

.image-text-home h4 {
  margin: 2px;
  font-weight: 500;
  font-size: 50px;
}

@media screen and (max-width: 767px) {
  .box {
    width: 70px;
    height: 70px;
    padding-bottom: 45%;
    margin-bottom: 5%;
  }
  .butterfly{
    background-image: url('./assets/Backgrounds/bg-p1.webp');
    background-repeat: round;
    padding: 21% 0px;
    font-size: 16px;
  }
}
.butterfly{
  margin-bottom: 30px;
  font-size: 20px;
  background-image: url('./assets/Backgrounds/bg-p1.webp');
  background-repeat: no-repeat;
  min-width: 100%;
  padding: 25% 0px;
  background-size: contain;
  background-position: center;
}
.pakagesbg {
  background-position: center;
  background-size: cover;
  padding: 20px;
  padding: 1px 0px;
  background-image: url('./assets/Backgrounds/bg-p1.webp');
  background-repeat: no-repeat;
}
#packages {
  margin: 8% 5%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#packages h2{
  margin: 0;
}
#packages > p{
  width: 100%;
}
.packageContainer {
  box-shadow: 0px 0px 5px 3px rgba(255, 0, 0, 0.3);
  width: 45%;
  padding: 10px 20px;
  background-color: #f0adbedd;
  border-right: 1px solid rgba(128, 128, 128, 0.329);
  border-left: 1px solid rgba(128, 128, 128, 0.181);
  border-bottom: 1px solid gray;
  border-radius: 20px;
  transition: 0.7s ease-in-out;
  margin: 10px;
  margin-left: 40px;
}
.midpackage{
  background-color: aliceblue;
}
.packageContainer h3 > span:first-child{
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 24px;
}
.packageContainer:hover {
  background-color: #DF92A3;
  
}
.packageContainer hr {
  width: 80%;
  mix-blend-mode: color;
  border-color: blue;
}
.btn-shadow{
  box-shadow: 2px 3px 2px rgba(255, 0, 132, 0.3),
              -2px 3px 2px rgba(255, 0, 132, 0.3);
}
ul {
  list-style: none;
  text-align: left;
  margin-left: -20px;
}

.tab-active {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #560981;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.tab {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #C1C1C1;
  background-color: #E8E8E8;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.packagebtn {
  padding: 10px 32px;
  border: none;
  border-radius: 5px;
  color: black;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.btn2{
  padding: 10px 32px;
  border: none;
  border-radius: 5px;
  color: white !important;
  background-color: black !important;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.btn {
  padding: 10px 32px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: black;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btn:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
}

.onetime {
  font-size: medium;
  font-weight: 300;
}

.image-txt-home p{
  margin: 0;
}
@media (max-width: 767px) {
  .dotsstyle{
    max-width: 100%;
  }
  #aboutus div{
    width: 100%;
    font-size: 12px;
  }
  .secondCover{
    width: 70%;
    margin-top: -50px;
  }
  .ourpackagesheaderbtn{
    padding: 10px 25px;
  }
  .pakagesbg {
    padding: 10px;
  }
  
  .packageContainer {
    width: 80%;
    margin: 0 !important; 
    margin-top: 20px !important;
  }
  .btn2{
    margin-top: 30px;
  }
}

#CustomModel{
  padding: 10px 0px;
}
#CustomModel>div {
  background-color: #EFF0F2;
  width: 90%;
  margin: 2% 5%;
  padding: 2% 0%;
  border-radius: 10px;
} 

#exploreProducts {
  margin: 1% 15%;
}

#exploreProducts .btn {
  padding: 15px 40px;
}

#exploreProducts>h2 {
  text-align: left;
}

#exploreProducts>div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.flexbox {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 110%;
}

.productsContainer {

  position: relative;
  z-index: 1;
  margin-top: 5%;
  flex-wrap: wrap;
}

.productCard {
  width: 21%;
  margin: 0;
  margin-bottom: 10%;
}

.productCard>div {
  background-color: #C1C1C1;
  padding: 0px 15% 0px;
  border-radius: 10px;
}

.productCard img {
  margin-top: -15%;
  margin-bottom: -7%;
}

.dark-bg {
  margin-top: -5%;
  padding: 10px 10px 35px 10px !important;
  background-color: black !important;
  color: white;
  border: 2px dotted lightgreen;
  border-radius: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dark-bg-content {
  flex-grow: 1;
}

.dark-bg p {
  margin: 0;
  margin-top: 10px;
}

.productCard .image-text-home {
  position: absolute;
  top: 5px;
  left: 85%;
  transform: translateX(-60%);
  color: black;
  background-color: white;
  border-radius: 10px;
  width: 15%;
  text-align: -webkit-center;
  padding: 2px 10px;
}

.nomargin {
  margin: 0;
}

.add-btn {
  border-radius: 50%;
  background-color: rgb(119, 255, 28);
  color: white;
  font-weight: bolder;
  padding: 0;
  height: 30px;
  width: 30px;
  font-size: large;
  border: none;
  
}


@media screen and (max-width: 768px) {
  .productsContainer {
    flex-wrap: wrap;
  }

  .productCard {
    width: 45%;
    margin-bottom: 0;
    margin-top: 30px;
    padding-top: 20px;
  }

  .productsContainer {
    flex-wrap: initial;
  }

  .productCard {
    width: 21%;
    margin-bottom: 10%;
    
    margin-left: 10px;
  }

  #affiliate>.image-text {
    top: 50%;
    padding: 12% 0%;
  }
}

 @media screen and (min-width: 1000px) {
  .handcard{
    height: 165px !important;
  }


#affiliate > img, #affiliate>.overlay{
  border-radius: 20px;
}
#affiliate>.overlay {
  background: rgb(0,0,0);
  background: linear-gradient(54deg, rgba(0,0,0,0.5243347338935574) 0%, rgba(255,255,255,0.0) 50%);
  margin: 0% 10%;
}


  #affiliate>.image-text-home {
    top: 50%;
    padding: 12% 0%;
  }
}


#aboutus-contact {
  margin-top: 20px;
  display: flex;
  padding: 20px 10%;
  justify-content: space-between;
}

#aboutus-contact>div {
  width: 18%;
  text-align: left;
}

#aboutus-contact h2 {
  font-size: large;
  font-weight: 500;
}

#aboutus-contact ul {
  margin: 0;
  padding: 0;
}

#aboutus-contact>.links>ul>li {
  border-left: #F75797 2px solid;
  padding-left: 5px;
  margin: 5px;
}

#enquiry {
  padding: 5% 15%;
  display: flex;
  justify-content: space-between;
}

.nowrap{
  flex-wrap: nowrap !important;
}
footer {
  background: linear-gradient(to right, blue, purple, red, orange, yellow);
  width: 100%;
  padding: 20px 0px;
  text-align: center;
  color: white;
  font-size: large;
}

.enquiry-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 10%;
}


@media screen and (min-width: 768px) {
  .enquiry-section {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .enquiry-form,
  .enquiry-info {
    padding-top: 20px;
    width: 45%;
  }
}


.enquiry-form h2 {
  font-weight: bold;
}

.red-text {
  color: red;
}
.enquiry-form{
  padding: 20px 5%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.enquiry-form form {
  display: flex;
  flex-direction: column;
  
}
.enquiry-form input[type="text"]{
  max-width: 44% !important;
}
.enquiry-form input[type="text"],
#detailsenquiry,
.enquiry-form input[type="email"] {
  background-color: #f9b4e076;
  border: none;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  max-width: 93%;
}

.enquiry-form button {
  background-color: #A020F0;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.enquiry-form button:hover {
  background-color: #8A2BE2;
}

.enquiry-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 50px;
  text-align: left; 
  padding: 5px;   
}

.enquiry-info h1 span {
  color: #A020F0;
}

.enquiry-info p {
  line-height: 1.5;
}

.enquiry-info h3 {
  font-weight: bold;
  margin-top: 40px;
  text-align: left;
} 


.enquiry-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
}

.enquiry-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: purple;
  border-radius: 50%;
  margin-right: 1em;
}

.enquiry-icon i {
  color: white;
  font-size: 18px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.form-group input {
  flex: 1;
  margin-right: 10px;
}

.form-group textarea {
  flex: 1;
  margin-right: 10px;
  background-color: #FFFFE0;
  border: 1px solid black;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  min-height: 20vh;
}

.form-group input:last-child {
  margin-right: 0;
}


#testimony h2, .enquiry-info h2 {
  font-size: x-large;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

#testimony h1, .enquiry-info h1 {
  font-size: xx-large;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
#testimony h2, #testimony h3{
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}
#testimony h3{
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}


#testimony {
  text-align: left !important;
  margin: 10% 0px;
}

.testimonial-section::-webkit-scrollbar{
  display: none;
}
.testimonial-section {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.testimonial{
  min-width: 60%;
  padding-right: 5%;
}

.testimonial .testimonial-content {
  background-color: white;
  margin: 5px 0px 0px 5px;
  border-left: 5px solid #A020F0;
  border-radius: 0%;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4),
  -2px -2px 10px rgba(111, 111, 111, 0.2);
  display: flex;
}
.jumpinmobile{
  margin-left: -40px;
}
.testimonial-info {
  text-align: left;
  display: flex;
}

.testimonial:nth-child(odd) .testimonial-content {
  text-align: left;
}

.testimonial:nth-child(odd) .testimonial-info {
  text-align: left;
}

.testimonial:nth-child(even) .testimonial-content {
  text-align: left;
}

.testimonial:nth-child(even) .testimonial-info {
  text-align: left;
}

.testimonial-content {
  padding: 20px;
  border-radius: 10px;
  display: flex;
}

.testimonial-info {
  margin-top: 1em;
  display: flex;
}

.testimonial-picture {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  border-radius: 50%;
  max-width: 100%;
}

.testimonial-author-info h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.testimonial-author-info p {
  font-size: 14px;
  margin: 0;
  color: #666;
}

.zoom:hover{
  scale: 1.1;
}
@media screen and (max-width: 768px) {
  .testimonial{
    font-size: 12px;    
  }
  .testimonial-section{
    margin-top: 10px;
    width: 74vw;
  }
  .enquiry-section {
    flex-direction: column;
  }
  .enquiry-info {
    margin-left: 0px;
    padding-top: 20px;   
  }
  .enquiry-form,
  .enquiry-info {
    width: 100%;    
  }
  #affiliate>.overlay, #affiliate>img{
    margin: 0% 10%;
    height: auto !important;
  }
  #affiliate {
    cursor: pointer;
  }
  #affiliate>.image-text-home {
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    padding: 0px 10%;    
  }
  .image-txt-home > p{
    margin-left: 5px;
    font-size: 12px;
  }
  .packagesbtncontainer{
    padding: 0px !important;
    margin-top: 20px;
  }
  #aboutus-contact {
    width: 80%;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
    #aboutus-contact>div{
      width: auto ;
    } 
    #aboutus-contact p{
      width: 100% !important;
    }
    .links ul, .contactlinks ul{
      display: flex;
      flex-wrap: wrap;
    }
    #packages > div{
      flex-direction: column;
    }
}

#aboutus-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex-div{
  margin-left: -6%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.aboutus-content {
  flex-basis: 100%;
}

.logo-png {
  height: 100px;
  width: 100px;
}

#aboutus-contact p {
  padding: 0;
  margin: 0;
  width: 150%;
}

.contactlinks,
.links {
  margin-top: 2em;
  line-height: 2em;
}
.contactlinks h2{
  text-align: left;
  color: black;
  text-decoration: none !important;
}
.links ul,
.contactlinks ul {
  list-style: none;
  padding: 0;
}

.links li,
.contactlinks li {
  margin-bottom: 0.5em;
}

.protfolio {
  height: 200px;
  width: 270px;
}

.enquiry-icon.purple-circle:hover .fa {
  color: #560981 !important;
}
.enquiry-icon.purple-circle:hover{
  background-color: white;
}
.halfbutterfly{
  position: absolute;
  z-index: -1;
  right: 0;
  width: 30vw;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.popup-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  min-width: 300px;
  min-height: 250px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 223;
}

.popup-form h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.popup-form form {
  display: flex;
  flex-direction: column;
}

.popup-form label {
  font-size: 16px;
  margin-bottom: 5px;
}

.popup-form input,
.popup-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.popup-form input:focus,
.popup-form textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.popup-form button[type="submit"] {
  background-color: black;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute; 
  bottom: 10px; 
  right: 10px; 

}

.popup-form button[type="submit"]:hover {
  background-color: gray;
}

.popup-form .input-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.popup-form .input-group label {
  font-size: 16px;
  margin-bottom: 5px;
  display: inline-block;
}

.popup-form .input-group input,
.popup-form .input-group textarea {
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  flex: 1;
  margin-left: 10px;
  margin-right: 10px; 
}
.popup-form .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

/* categories css */

.containerCat {
  position: relative;
  width: 100%;
  height: 35vh;
}

.top-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: #f2f2f2;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  box-sizing: border-box;
}
.content img {
  margin-top: 10vh;
  width: 70%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.content h2 {
  margin-top: 15.5vh;
    text-align: center;
  width: 20%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  color: orange;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.bg-section {
  background-image: url('./assets/Categories/overlay-art.webp');
  padding: 240px 0px;
  background-size: cover;
  background-position: center;
}
.bg-section .container, .bg-section2 .container  {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  width: 70%;
  margin: 0 auto;
}
.bg-section .container h2 {
  text-align: center;
}
.containerCat  h2 {
  text-align: bottom  !important;
}
.bg-section .container p {
  text-align: center;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 20px;
}
.bg-section2 {
  
  background: linear-gradient(to right, transparent 50%, #f7cbd8 50%, #f7cbd8),
              linear-gradient(to left, transparent 50%, #f7cbd8 50%, #f7cbd8);
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-section2 {
  margin-top: 6vh;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-section2 .container h2 {
  text-align: center;
}
.bg-section2 .container p {
  text-align: left;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 20px;
}
.bg-section2 button {
  background-color: #A020F0;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.bg-section2 button:hover {
  background-color: #8A2BE2;
}
.thirdsection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.bg-section2 .container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bg-section2 input,
.bg-section2 textarea {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 10px;
  width: 97%;
  margin-bottom: 10px;
  border: none;
  background: peachpuff;
}
.bg-section2 textarea {
  min-height: 15vh;
}

.bg-section2 input:focus,
.bg-section2 textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 1000px) and (max-width: 1033px) {
#aboutus-contact
{
  padding: 20px 5% !important;
}
#aboutus-contact>div{
  width: 15%;
}
}
@media screen and (max-width: 1000px) {
  .containerCat {
    position: relative;
    width: 100%;
    height: 25vh;
  }
  .bg-section {
    background-image: url('./assets/Categories/overlay-art.webp');
    padding: 70px 0px;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 780px) {
  .containerCat {
    position: relative;
    width: 100%;
    height: 28vh;
  }
  .bg-section {
    background-image: url('./assets/Categories/overlay-art.webp');
    padding: 40px 0px;
    background-size: cover;
    background-position: center;
  }
  .top-section {    
    height: 200px;
    background: #f2f2f2;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 30% 50%;
    border-bottom-right-radius: 30% 50%;
  }
}

#productsHome{
  cursor: pointer;
}
.add-btn{
  cursor: pointer;
}