.heading{
    padding-top: 60px;
    text-align: center;
    margin-top: 40px;
}

.containerHeading{
    text-align: center;
}

.bottomHeading{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.glassEffect {
    position: relative;
    width: 80%;
    height: 100%;
    background-color: lightgray;
    background-image: linear-gradient(to bottom left, lightgray, white);
    opacity: 0.8;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
  }

  .productsbg {
    background-image: url('./assets/Backgrounds/productpagebg.webp');
    background-repeat: no-repeat;
    background-size: 200vh;
    background-position: center;
  
  }


#exploreProducts {
    margin: 5% 15%;
  }
  
  #exploreProducts .btn {
    padding: 15px 40px;
  }
  
  #exploreProducts>h2 {
    text-align: left;
  }
  
  #exploreProducts>div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .flexbox {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 110%;
  }
  
  .productsContainer {
  
    position: relative;
    z-index: 1;
    margin-top: 5%;
    flex-wrap: wrap;
  }
  
  .productCard {
    width: 21%;
    margin: 0;
    margin-bottom: 10%;
  }
  
  .productCard>div {
    background-color: #C1C1C1;
    padding: 0px 15% 6px;
    border-radius: 10px;
  }
  
  .productCard img {
    margin-top: -15%;
  }
  
  .dark-bg {
    margin-top: -5%;
    padding: 10px 10px 35px 10px !important;
    background-color: black !important;
    color: white;
    border: 2px dotted lightgreen;
    border-radius: 10px;
    text-align: left;
    display: flex;
  }
  
  .dark-bg p {
    margin: 0;
    margin-top: 10px;
  }
  
  .productCard .image-text {
    position: absolute;
    top: 5px;
    left: 85%;
    transform: translateX(-60%);
    color: black;
    background-color: white;
    border-radius: 10px;
    width: 15%;
    text-align: -webkit-center;
    padding: 2px 10px;
  }
  
  .nomargin {
    margin: 0;
  }
  
  .add-btn {
    border-radius: 50%;
    background-color: rgb(119, 255, 28);
    color: white;
    font-weight: bolder;
    padding: 0;
    height: 30px;
    width: 30px;
    font-size: large;
    border: none;
    position: relative;
    left: 5%;
    top: 35px;
  }
  
  @media screen and (max-width: 768px) {
    .productsContainer {
      flex-wrap: wrap;
    }
  
    .productCard {
      width: 45%;
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .productsContainer {
      flex-wrap: initial;
    }
  
    .productCard {
      width: 21%;
      margin-bottom: 10%;
      
      margin-left: 10px;
    }
  }
  
  #affiliate>.overlay, #affiliate>img{
    margin: 0% 10%;
    height: auto !important;
  }
  #affiliate {
    cursor: pointer;
  }
  #affiliate>.image-text {
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    padding: 0px 10%;    
  }
  .image-txt > p{
    margin-left: 5px;
    width: 90%;
  }
  .affHead{
    padding-left: 20%;
    text-align: left !important;
    width: auto !important;
    max-width: 100% !important;
  }
  .image-container {
    position: relative;
    text-align: center;
  }
  .text-div {
    background-color: #ffe6e6; 
    border-radius: 10px;
    margin-top: 30px;
    color: #000;
    width: 75%;
    margin-left: 12.5%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    
  }
  
  @media screen and (max-width: 768px) {
    
    .text-div {
      font-size: 18px; 
      padding: 10px; 
    }
  }
  .email-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    text-align: center;
}
.input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.input-group input[type="email"] {
    margin-right: 10px;
    width: 300px;
    padding: 10px 20px;
    background-color: #ffe6e6;
}
.submit-button {
    color: #ffffff;
    background-color: #000;
    padding: 10px 20px;
}
  